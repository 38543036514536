<template>
  <div :show="loading">
    <!-- Left Card -->
    <b-card no-body v-if="$store.state['app-ecommerce'].cartItems.length > 0">
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Métodos de Pago</b-card-title>
      </b-card-header>
      <b-card-body>
        <!-- Radios -->
        <b-form-group>
          <b-form-checkbox
            v-for="option in payments"
            :key="option.id"
            v-model="selected"
            multiple
            :value="option"
            @input.native="paymentMethod = option.code"
            @change="paymentMethodChange($event)"
          >
            <feather-icon icon="DollarSignIcon" size="15" class="mr-10" />
            {{ option.name }}
          </b-form-checkbox>
        </b-form-group>
        <hr v-if="isAdmin || isCashier" />

        <div v-if="isAdmin || (isCashier && selectedUsers == null)">
          <b-form-input
            placeholder="Buscar usuario por: nombre, apellido, teléfono o email"
            v-model="searchUser"
            @input="handleInput"
          />
          <ul class="user-list">
            <li v-for="user in filteredUsers" :key="user.id" class="user-item">
              <span
                v-if="user.wallet"
                class="cursor-pointer"
                @click="openUser(user)"
              >
                <span class="user-name">{{
                  user.name + " " + user.last_name
                }}</span>
                <span class="user-wallet">
                  {{ user.wallet.type }}
                  <span class="badge balance-badge">{{
                    getBalance(user.wallet.balance)
                  }}</span>
                </span>
                <span v-if="user.wallet.max_limit">
                  Credito:
                  {{
                    user.wallet.max_limit
                      | currency({ symbol: "$", precision: 2 })
                  }}
                </span>
                <br />
                <span v-if="user.wallet.expiry_date">
                  Disponible hasta: {{ user.wallet.expiry_date }}
                </span>
                <br />
                <span class="user-school">{{ user.school.name }}</span>
              </span>
            </li>
          </ul>
        </div>

        <div v-if="selectedUsers" class="user-item">
          <div class="user-info">
            <span class="user-name"
              >{{ selectedUsers.name }} {{ selectedUsers.last_name }}</span
            >
            <span class="user-wallet">
              {{ selectedUsers.wallet.type }}
              <span class="badge balance-badge">{{
                getBalance(selectedUsers.wallet.balance)
              }}</span>
            </span>

            <span v-if="selectedUsers.wallet.max_limit" class="user-credit">
              Crédito:
              {{
                selectedUsers.wallet.max_limit
                  | currency({ symbol: "$", precision: 2 })
              }}
              <feather-icon
                v-if="validationView"
                icon="PlusSquareIcon"
                class="text-success cursor-pointer"
                size="19"
                @click="openModalExtCredit"
              />
            </span>
            <div
              v-if="selectedUsers.wallet.ext_credit"
              class="d-flex flex-column extra-credit"
            >
              <span>
                Extra Crédito:
                {{
                  selectedUsers.wallet.ext_credit
                    | currency({ symbol: "$", precision: 2 })
                }}
              </span>
              <span>Razón: {{ selectedUsers.wallet.razon_ext_credit }}</span>
            </div>

            <span v-if="selectedUsers.wallet.expiry_date" class="expiry-date">
              Disponible hasta: {{ selectedUsers.wallet.expiry_date }}
            </span>

            <div>
              <b-badge class="bg-dark">{{ selectedUsers.school.name }}</b-badge>
            </div>
          </div>

          <!-- Botones de acción -->
          <div id="container-b-card">
            <b-button
              variant="outline-primary"
              size="sm"
              @click="userCard(selectedUsers)"
            >
              <feather-icon icon="UserIcon" size="14" />
            </b-button>
            <b-button
              variant="outline-danger"
              size="sm"
              @click="selectedUsers = null"
            >
              <feather-icon icon="XIcon" size="14" />
            </b-button>
          </div>

          <!-- Modal para ver la información del usuario -->
          <b-modal
            v-model="showUserModal"
            size="lg"
            hide-footer
            centered
            no-close-on-backdrop
          >
            <b-overlay
              :show="loadingModal"
              rounded
              variant="transparent"
              opacity="0.33"
              blur="2px"
            >
              <b-card>
                <user-view-user-info-card
                  v-if="selectedUsers"
                  :user-data="showUser"
                />
              </b-card>
            </b-overlay>
          </b-modal>
        </div>

        <br v-if="isAdmin || isCashier" />
        <button
          v-if="isCashier && !isAdmin && !selectedUsers && selected.length > 0"
          class="btn btn-primary"
          @click="openCamera = !openCamera"
          title="Busca el código QR de la billetera de un usuario"
        >
          {{ openCamera ? "Cerrar" : "Abrir" }} Cámara QR
        </button>
        <br />
        <qrcode-stream
          v-if="isCashierisAdminIsWallet && openCamera"
          @decode="onDecode"
        ></qrcode-stream>
      </b-card-body>
    </b-card>

    <!-- Right Card -->
    <div class="amount-payable" v-if="!openCamera">
      <!-- checkout-options -->
      <b-card
        :title="messagePurchaseList"
        v-if="$store.state['app-ecommerce'].cartItems.length > 0"
      >
        <div v-if="selected.length > 0">
          <button class="btn btn-primary" @click="selected = []">
            Reiniciar Métodos de Pago
          </button>
        </div>
        <ul class="list-unstyled price-details" v-if="isShowFieldsTemp">
          <div v-for="paymentMethod in selectedData" :key="paymentMethod.id">
            <hr />
            <h5 class="mt-1">
              Ingrese el monto a pagar en dólares: {{ paymentMethod.name }}
            </h5>
            <div v-if="paymentMethod.code == 'WALLET'">
              <input
                class="form-control"
                type="number"
                v-model="paymentMethod.amount"
              />
            </div>
            <div v-else>
              <input
                class="form-control"
                type="number"
                v-model="paymentMethod.amount"
              />
              <div v-if="paymentMethod.required_input == 1" class="mt-1">
                <b-row>
                  <b-col
                    md="12"
                    :lg="!isAdmin && !isCashier ? '12' : '6'"
                    class="mb-1"
                  >
                    <h5>
                      Ingrese el número de referencia para:
                      {{ paymentMethod.name }}
                    </h5>
                    <input
                      class="form-control"
                      type="text"
                      v-model="paymentMethod[`${paymentMethod.code}`]"
                      :placeholder="`Ingrese el número de referencia para: ${paymentMethod.name}`"
                    />
                  </b-col>
                  <b-col
                    md="12"
                    lg="6"
                    v-if="
                      (isAdmin || isCashier) &&
                      paymentMethod.code == 'PAGO_MOVIL'
                    "
                  >
                    <h5>
                      Numero telefonico del emisor: {{ paymentMethod.name }}
                    </h5>
                    <input
                      class="form-control"
                      type="text"
                      v-model="paymentMethod[`${paymentMethod.emitterPhone}`]"
                      :placeholder="`Numero telefonico del emisor: ${paymentMethod.name}`"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <li
            v-for="product in $store.state['app-ecommerce'].cartItems"
            :key="product.id"
            class="price-detail"
          >
            <br />

            <div>({{ product.qty }}) {{ product.name }}</div>

            <div v-if="product.discount_type == 'percent'">
              Precio neto:
              {{
                (product.price * product.qty)
                  | currency({ symbol: "$", precision: 2 })
              }}
            </div>

            <div v-if="product.discount_type == 'percent'">
              Descuento: {{ product.discount
              }}{{ product.discount_type == "percent" ? "%" : "$" }}
            </div>

            <div v-if="product.discount_type == 'percent'">
              Total con Descuento:
              <strong>{{
                showPriceTotal(product)
                  | currency({ symbol: "$", precision: 2 })
              }}</strong>
            </div>
          </li>

          <hr />

          <div>
            Total a Pagar:
            <span>
              {{ totalAmountOrder | currency({ symbol: "$", precision: 2 }) }} -
              {{
                ($store.state.bank.data.amount * totalAmountOrder)
                  | currency({ symbol: "VEF", precision: 2 })
              }}
            </span>
            <hr />

            <div>
              <span v-if="totalAmount > 0">
                Resta por Pagar:
                <strong>
                  {{ totalAmount | currency({ symbol: "$", precision: 2 }) }} -
                  <span>
                    {{
                      ($store.state.bank.data.amount * totalAmount)
                        | currency({ symbol: "VEF", precision: 2 })
                    }}
                  </span>
                </strong>
              </span>
            </div>
          </div>
        </ul>
        <div class="checkout-options">
          <b-card>
            <div class="price-details">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
              >
                <b-button
                  v-if="!isCashier || (!isAdmin && paymentMethod !== 'WALLET')"
                  @click="validatePay()"
                  variant="primary"
                  block
                >
                  Pagar
                </b-button>
                <div v-else>
                  <b-button @click="validatePay()" variant="primary" block>
                    Pagar
                  </b-button>
                </div>
              </b-overlay>
            </div>
          </b-card>
        </div>
      </b-card>
      <b-modal
        v-model="showModal"
        hide-footer
        centered
        title="Reportar transacción con Pago Móvil"
        no-close-on-backdrop
      >
        <b-overlay
          :show="loadingModal"
          rounded
          variant="transparent"
          opacity="0.33"
          blur="2px"
        >
          <b-form>
            <b-form-group label="Referencia">
              <validation-provider
                #default="{ errors }"
                name="Referencia"
                rules="required"
              >
                <b-form-input v-model="refId" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
          <footer class="modal-footer p-0">
            <b-button variant="outline-primary modal-btn" @click="close">
              Cancelar
            </b-button>
            <b-button variant="primary" @click="showModal = !showModal">
              Guardar
            </b-button>
          </footer>
        </b-overlay>
      </b-modal>

      <!-- Modal de confirmación de extra credito -->
      <b-modal
        v-model="isModalExtCredit"
        title="Credito Extra"
        hide-footer
        centered
        @hide="clearModalExtCredit"
        class="rounded-modal"
      >
        <template #default>
          <b-row>
            <b-col v-if="!isExtCredit" class="text-center">
              <feather-icon
                icon="AlertTriangleIcon"
                class="text-warning mb-2"
                size="80"
              />
              <h4>
                ¿Deseas agregar un crédito extra al usuario
                <span class="text-success">{{ selectedUsers.name }}</span
                >?
              </h4>
              <b-row class="mt-2">
                <b-col>
                  <b-button
                    class="rounded-button mr-1"
                    variant="primary"
                    @click="isExtCredit = true"
                  >
                    Sí
                  </b-button>
                  <b-button
                    class="rounded-button"
                    variant="danger"
                    @click="isModalExtCredit = false"
                  >
                    No
                  </b-button>
                </b-col>
              </b-row>
            </b-col>

            <b-col v-else>
              <div class="text-center">
                <feather-icon
                  icon="PlusCircleIcon"
                  class="text-success mb-1"
                  size="80"
                />
                <h4>
                  Saldo actual:
                  <span class="text-success">{{
                    getBalance(selectedUsers.wallet.balance)
                  }}</span>
                </h4>
                <h4>
                  Credito disponible:
                  <span class="text-success">{{
                    selectedUsers.wallet.max_limit
                      | currency({ symbol: "$", precision: 2 })
                  }}</span>
                </h4>
              </div>

              <b-form @submit.prevent="addExtCreditUser">
                <b-row>
                  <b-col>
                    <b-form-group label="Cantidad a agregar" class="mt-1">
                      <b-form-input
                        v-model="qtyCredit"
                        type="text"
                        placeholder="Ingrese la cantidad"
                      />
                    </b-form-group>

                    <b-form-group label="Razon *" class="mt-1">
                      <b-form-input
                        v-model="razonExtCredit"
                        type="text"
                        placeholder="Ingrese la razon"
                      />
                      <small v-if="!razonExtCredit" class="text-danger"
                        >El campo Razon es requerido</small
                      >
                    </b-form-group>

                    <div class="d-flex justify-content-between mb-2">
                      <div>
                        <strong>Credito total:</strong>
                        <span class="text-success">
                          {{
                            (Number(toltalBalanceUser) + Number(qtyCredit))
                              | currency({ symbol: "$", precision: 2 })
                          }}
                        </span>
                      </div>
                      <div>
                        <strong>Total a pagar:</strong>
                        <span class="text-success">
                          {{
                            totalAmountOrder
                              | currency({ symbol: "$", precision: 2 })
                          }}
                        </span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="!qtyCredit && !razonExtCredit"
                >
                  Agregar
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BButton,
  BFormCheckbox,
  BOverlay,
  BModal,
  VBModal,
  BForm,
  BRow,
  BCol,
  BBadge,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import UserViewUserInfoCard from "@/views/Users/ViewUser/UserViewUserInfoCard.vue";
localize("es", es);
import vSelect from "vue-select";
import store from "@/store";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BButton,
    BFormCheckbox,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    BOverlay,
    BModal,
    VBModal,
    ValidationProvider,
    ValidationObserver,
    BForm,
    vSelect,
    BRow,
    BCol,
    UserViewUserInfoCard,
    BBadge,
  },
  directives: {
    "b-modal": VBModal,
  },
  computed: {
    selectedData() {
      return this.selected.sort((a, b) => a.id - b.id);
    },
    messagePurchaseList() {
      if (this.isCashier && this.selectedUsers) {
        return (
          "Listado de Compras para: " +
          this.selectedUsers?.name +
          " " +
          this.selectedUsers?.last_name
        );
      } else {
        return "Listado de Compras";
      }
    },
    isCashierisAdminIsWallet() {
      return (
        this.isCashier ||
        this.isAdmin ||
        this.selected.find((item) => item.code == "WALLET")
      );
    },
    totalAmountOrder() {
      let total = 0;
      let items = this.$store.state["app-ecommerce"].cartItems;
      items.forEach((i) => {
        total += this.showPriceTotal(i);
      });
      return total;
    },
    totalAmount() {
      let total = 0;
      let paymentTotal = 0;

      // Calcula el total de los artículos en el carrito
      const items = this.$store.state["app-ecommerce"].cartItems;
      items.forEach((item) => {
        total += this.showPriceTotal(item);
      });

      this.selected.forEach((paymentMethod) => {
        const amount = parseFloat(paymentMethod.amount);
        if (!isNaN(amount)) {
          paymentTotal += amount;
        }
      });

      // Resta los pagos del total a pagar
      return total - paymentTotal;
    },
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
    isAdmin() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
    },
    validateBalance() {
      if (Number(this.selectedUsers?.wallet?.balance) >= 0) {
        return (
          Number(this.selectedUsers?.wallet?.balance) +
            Number(this.selectedUsers?.wallet?.max_limit) <
          this.totalAmountOrder
        );
      } else {
        return (
          Number(this.selectedUsers?.wallet?.max_limit) < this.totalAmountOrder
        );
      }
    },
    toltalBalanceUser() {
      if (Number(this.selectedUsers?.wallet?.balance) >= 0) {
        return (
          Number(this.selectedUsers?.wallet?.balance) +
          Number(this.selectedUsers?.wallet?.max_limit)
        ).toFixed(2);
      } else {
        return Number(this.selectedUsers?.wallet?.max_limit).toFixed(2);
      }
    },
    validationView() {
      const { wallet } = this.selectedUsers;
      const hasRequiredRole = this.isCashier || this.isAdmin;

      const isValidWallet = wallet && {
        hasCredit: wallet.max_limit !== null,
        isPostpaid: wallet.type === "Pospago",
        needsMoreCredit: this.validateBalance,
      };

      return (
        hasRequiredRole &&
        isValidWallet.hasCredit &&
        isValidWallet.isPostpaid &&
        isValidWallet.needsMoreCredit
      );
    },
  },
  data() {
    return {
      isShowFieldsTemp: true,
      invalid: false,
      required,
      refId: null,
      showModal: false,
      invalid: false,
      payments: [],
      selected: [],
      paymentMethod: null,
      walletId: null,
      order: {},
      loading: false,
      loadingModal: false,
      validWallet: true,
      openCamera: false,
      timeOutSearch: null,
      isSearching: false,
      selectedUsers: null,
      allUsers: [],
      filteredUsers: [],
      loadingSearchUser: false,
      searchUser: null,
      timeout: null,
      showUserModal: false,
      dataId: null,
      loadingModal: false,
      showUserModal: false,
      isModalExtCredit: false,
      isExtCredit: false,
      qtyCredit: 0,
      razonExtCredit: "",
      showUser: {},
      // qtyProduct: 0,
    };
  },
  mounted() {
    this.getPayments();
  },
  created() {},
  watch: {
    selected(payment) {
      if (this.isCashier) {
      }
    },
    // selectedUsers(newVal) {
    //   if (newVal) {
    //     this.lastSelectedUser = newVal.name;
    //   }
    // },
    totalAmountOrder() {
      this.paymentMethodChange(this.selected);
    },
  },
  methods: {
    getBalance(balance) {
      return parseFloat(balance).toFixed(2);
    },
    openUser(user) {
      this.selectedUsers = user;
      this.searchUser = "";
      this.filteredUsers = [];
    },
    userCard(dataUsers) {
      this.showUserModal = true;
      const dataId = dataUsers.id;
      this.getUserId(dataId);
    },
    async getUserId(id) {
      try {
        this.loadingModal = true;
        await this.$store.dispatch("user/showUser", id);
        this.showUser = this.$store.state.user.showUser;
      } catch (error) {
      } finally {
        this.loadingModal = false;
      }
    },
    async fSearchUser(e) {
      const res = await this.$store.dispatch("search/fSearchUser", e);
      this.filteredUsers = res;
    },
    handleInput(e) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.fSearchUser(e);
      }, 500);
    },
    changeUserQuantity(user) {
      if (typeof user === "string" && user !== this.lastSelectedUser) {
        if (this.timeOutSearch) {
          clearTimeout(this.timeOutSearch);
        }
        this.loadingSearchUser = true;
        this.timeOutSearch = setTimeout(async () => {
          try {
            const res = await this.$store.dispatch("user/searchUsers", {
              search: user,
            });
            this.allUsers = res;
          } catch (error) {
            console.log(
              "error@ECommerceCheckoutStepPayment#changeUserQuantity"
            );
          } finally {
            this.loadingSearchUser = false;
          }
        }, 1000);
      }
    },
    async paymentMethodChange(options) {
      this.isShowFieldsTemp = false;
      await new Promise((resolve) => setTimeout(resolve, 100));

      if (options.length === 1) {
        this.selected[0].amount = this.totalAmountOrder;
      } else if (options.length > 1) {
        this.selected.forEach((item) => {
          item.amount = null;
        });
      }

      this.isShowFieldsTemp = true;
    },
    setTotalAmountRate(item) {
      if (item.isBs == 0) return;
      const rate = this.$store.state.bank.data.amount;
      if (item.amount && !isNaN(item.amount)) {
        const amount = parseFloat(item.amount / rate).toFixed(2);
        item.amount = amount;
      } else {
        item.amount = ""; // Deja el campo vacío si el monto no es válido
      }
    },
    debounce(func, timeout = 2000) {
      /* let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      }; */
    },
    setTotalAmount(paymentMethod) {
      const index = this.selected.findIndex((pm) => pm.code === "WALLET");
      if (index !== -1) {
        this.$set(this.selected, index, {
          ...paymentMethod,
          amount: this.totalAmount,
        });
        this.validWallet = false;
      }
    },
    close() {
      this.showModal = false;
      this.selected = null;
      this.paymentMethod = null;
    },
    onDecode(key) {
      this.walletId = key;
      this.payWallet();
    },
    showPriceTotal({ discount_type, price, discount, qty }) {
      if (discount_type == "percent") {
        return (price - price * (discount / 100)) * qty;
      } else {
        if (discount_type == null || discount == null) {
          return price * qty;
        }

        return (price - discount) * qty;
      }
    },
    async getPayments() {
      this.payments = await this.$store.dispatch("products/getPayments", {
        payment: "checkout",
      });

      this.payments.forEach((paymentMethod) => {
        if (typeof paymentMethod.amount === "undefined") {
          this.$set(paymentMethod, "amount", 0); // Inicializamos amount con 0
        }
      });

      const defaultW = this.payments.find((option) => option.code === "WALLET");
      if (defaultW) {
        this.selected.push(defaultW);
        this.paymentMethod = defaultW.code;

        this.paymentMethodChange([defaultW]);
      }
    },
    validatePay() {
      if (this.selected.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: "Debes de seleccionar al menos un método de pago",
          },
        });
        return;
      } else {
        for (const item of this.selected) {
          if (item.amount == null || item.amount == "" || item.amount == 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertCircleIcon",
                variant: "danger",
                text: "Debes de ingresar el monto a pagar en: " + item.name,
              },
            });
            return;
          } else if (
            item.required_input == 1 &&
            (item[item.code] == null || item[item.code].length < 4)
          ) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertCircleIcon",
                variant: "danger",
                text:
                  "El número de referencia para " +
                  item.name +
                  " debe tener al menos 4 caracteres",
              },
            });
            return;
          } else if (
            (this.isAdmin || this.isCashier) &&
            item.code == "PAGO_MOVIL" &&
            (item[item.emitterPhone] == null ||
              item[item.emitterPhone].length < 4)
          ) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertCircleIcon",
                variant: "danger",
                text: "El número de telefonico del emisor es obligatorio",
              },
            });
            return;
          } else if (
            //Valida que el monto a pagar no sea mayor al total de la compra
            item.amount > this.totalAmountOrder
          ) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertCircleIcon",
                variant: "danger",
                text:
                  "El monto a pagar en " +
                  item.name +
                  " no puede ser mayor al total de la compra",
              },
            });
            return;
          }
        }
        this.pay();
      }
    },
    async payWallet() {
      try {
        this.loading = true;
        this.order = {
          walletId: this.walletId
            ? this.walletId
            : this.selectedUsers?.wallet?.public_key,
          totalOrder: this.totalAmountOrder,
          payments: this.selected.map((item) => {
            return {
              paymentMethod: item.code,
              amount: this.totalAmountOrder,
            };
          }),
          order: this.$store.state["app-ecommerce"].cartItems.map((item) => {
            if (item.discount_type == "percent") {
              (item.price - item.price * (item.discount / 100)) * item.qty;
            } else {
              (item.price - item.discount) * item.qty;
            }

            return {
              productId: item.id,
              qty: item.qty,
              productPrice: item.price,
              price: item.price * item.qty,
              discount: item.discount,
              total: this.showPriceTotal(item),
            };
          }),
        };

        const res = await this.$store.dispatch("products/pay", this.order);

        if (res) {
          this.loading = false;
          this.$store.commit("app-ecommerce/EMPTY_CART");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Éxito",
              icon: "CheckCircleIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        }

        if (this.isCashier || this.isAdmin) {
          if (this.paymentMethod == "TRANSFERENCIA") {
            this.$router.push({
              name: "apps-invoice-ticket",
              params: { id: res.data.invoiceId },
            });
          } else {
            this.$router.push({
              name: "apps-invoice-ticket",
              params: { id: res.data.invoiceId },
            });
          }
        } else {
          this.$router.push({
            name: "apps-invoice-preview",
            params: { id: res.data.invoiceId },
          });
        }

        this.$store.commit("app-ecommerce/UPDATE_CART_ITEMS_COUNT", 0);
        this.$store.commit("app-ecommerce/EMPTY_CART");
      } catch (error) {
        this.loading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: error.response.data.message,
          },
        });
        console.log("error@ECommerceCheckoutStepPayment#pay");
      } finally {
        this.loading = false;
      }
    },
    async pay() {
      try {
        this.loading = true;
        this.order = {
          walletId: this.walletId
            ? this.walletId
            : this.selectedUsers?.wallet?.public_key,
          totalOrder: this.selected.reduce((acc, item) => {
            return acc + parseFloat(item.amount);
          }, 0),
          payments: this.selected.map((item) => {
            return {
              paymentMethod: item.code,
              amount: parseFloat(item.amount),
              refId: item[item.code],
              emitterPhone: item[item.emitterPhone],
            };
          }),
          order: this.$store.state["app-ecommerce"].cartItems.map((item) => {
            if (item.discount_type == "percent") {
              (item.price - item.price * (item.discount / 100)) * item.qty;
            } else {
              (item.price - item.discount) * item.qty;
            }

            return {
              productId: item.id,
              qty: item.qty,
              productPrice: item.price,
              price: item.price * item.qty,
              discount: item.discount,
              total: this.showPriceTotal(item),
            };
          }),
        };

        const res = await this.$store.dispatch("products/pay", this.order);

        if (res) {
          this.loading = false;
          this.$store.commit("app-ecommerce/EMPTY_CART");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Éxito",
              icon: "CheckCircleIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        }

        if (this.isCashier || this.isAdmin) {
          if (this.paymentMethod == "TRANSFERENCIA") {
            this.$router.push({
              name: "apps-invoice-ticket",
              params: { id: res.data.invoiceId },
            });
          } else {
            console.log("data", res.data.invoiceId);
            this.$router.push({
              name: "apps-invoice-ticket",
              params: { id: res.data.invoiceId },
            });
          }
        } else {
          this.$router.push({
            name: "apps-invoice-preview",
            params: { id: res.data.invoiceId },
          });
        }

        this.$store.commit("app-ecommerce/UPDATE_CART_ITEMS_COUNT", 0);
        this.$store.commit("app-ecommerce/EMPTY_CART");
      } catch (error) {
        this.loading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: error.response.data.message,
          },
        });
        console.log("error@ECommerceCheckoutStepPayment#pay");
      } finally {
        this.loading = false;
      }
    },

    openModalExtCredit() {
      this.isModalExtCredit = true;
      this.qtyCredit = (this.totalAmountOrder - this.toltalBalanceUser).toFixed(
        2
      );
    },

    async addExtCreditUser() {
      try {
        if (this.qtyCredit > 0 && this.razonExtCredit) {
          // await store.dispatch("user/extCredit", {
          //   id: this.selectedUsers.id,
          //   ext_credit: this.qtyCredit,
          //   razon_ext_credit: this.razonExtCredit,
          // });
          this.selectedUsers.wallet.max_limit = (
            Number(this.toltalBalanceUser) + Number(this.qtyCredit)
          ).toFixed(2);
          this.selectedUsers.wallet.ext_credit = this.qtyCredit;
          this.selectedUsers.wallet.razon_ext_credit = this.razonExtCredit;

          this.clearModalExtCredit();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Éxito",
              icon: "TashIcon",
              variant: "success",
              text: "Extra credito agregado correctamente.",
            },
          });
        }
      } catch (error) {
        this.clearModalExtCredit();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: "No se pudo agregar el extra credito.",
          },
        });
      }
    },

    clearModalExtCredit() {
      this.isModalExtCredit = false;
      this.isExtCredit = false;
      this.qtyCredit = 0;
      this.razonExtCredit = "";
    },
  },
};
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}

.user-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-item {
  position: relative;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-name {
  font-weight: bold;
  font-size: 1rem;
}

.user-wallet {
  display: flex;
  align-items: center;
  gap: 5px;
}

.balance-badge {
  background-color: #007bff;
  color: white;
  padding: 0.5em 0.75em;
  border-radius: 10px;
  font-size: 0.75em;
  margin-left: 10px;
  font-weight: bold;
}

.user-credit,
.expiry-date {
  font-size: 0.9rem;
}

.extra-credit {
  font-size: 0.9rem;
  font-weight: bold;
  color: #dc3545;
  background-color: #f8d7da;
  padding: 8px;
  border-radius: 5px;
  display: inline-block;
}
.buton-add-credit {
  margin-bottom: 4px;
}

#container-b-card {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
}
</style>
