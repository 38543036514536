<template>
  <div class="checkout-items">
    <div v-if="isCashier">
      <v-select
        :loading="loadingSearchProduct"
        v-model="selected"
        @input="changeProductQuantity"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="allProducts"
        label="name"
        placeholder="Seleccione un producto"
        :reduce="(item) => item"
      >
        <template v-slot:option="option">
          {{ option.name }}
        </template>
      </v-select>
    </div>
    <br />
    <vuescroll
      v-if="cartProducts.length"
      :ops="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      style="height: calc(110vh - 180px)"
      tagname="li"
    >
      <b-card
        v-for="(product, indexTr) in cartProducts"
        :key="indexTr"
        class="ecommerce-card"
        no-body
      >
        <!-- Product Image -->
        <div class="item-img">
          <b-link>
            <b-img
              :src="product.image"
              :alt="`${product.name}-${product.id}`"
            />
          </b-link>
        </div>

        <!-- Product Details: Card Body -->
        <b-card-body class="ml-1">
          <div class="item-name">
            <h6 class="mb-0">
              <b-link class="text-body">
                {{ product.name }}
              </b-link>
            </h6>
            <span v-if="product.category" class="item-company">
              {{ product.category.name }}
            </span>
          </div>
          <span v-if="product.stock" class="text-success mb-1"
            >Disponible {{ product.stock - product.qty }}</span
          >
          <div class="item-quantity">
            <span class="quantity-title">Cantidad:</span>
            <div>
              <b-form-input
                v-model="product.qty"
                type="number"
                @input="updateQtyInProduct($event, product)"
                :max="product.stock"
                size="sm"
                class="ml-75"
              />
            </div>
          </div>
          <span
            class="delivery-date text-muted"
            v-if="product.discount_type == 'percent'"
          >
            Descuento <span class="text-success">{{ product.discount }}%</span>
          </span>
        </b-card-body>

        <!-- Product Options/Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                {{ product.price | currency({ symbol: "$", precision: 2 }) }}
              </h4>
              <small>
                {{
                  ($store.state.bank.data.amount * product.price)
                    | currency({ symbol: "VEF", precision: 2 })
                }}
              </small>
            </div>
          </div>
          <b-button
            variant="light"
            class="mt-1 remove-wishlist"
            @click="removeProductFromCart(product)"
          >
            <feather-icon icon="XIcon" class="mr-50" />
            <span>Borrar</span>
          </b-button>
        </div>
      </b-card>
    </vuescroll>

    <!-- Modal de confirmación de stock -->
    <b-modal
      v-model="isModalQtyProduct"
      title="Stock máximo alcanzado"
      hide-footer
      centered
      @hide="clearModalQtyProduct"
      class="rounded-modal"
    >
      <template #default>
        <b-row>
          <b-col v-if="!isQtyProduct" class="text-center">
            <feather-icon
              icon="AlertTriangleIcon"
              class="text-warning mb-2"
              size="80"
            />
            <h3 class="font-weight-bold">Cantidad máxima</h3>
            <strong
              >Stock actual
              <span class="text-success">{{ editProduct.stock }}</span> pero
              requieres
              <span class="text-warning">{{ editProduct.qty }}</span></strong
            >
            <p>
              ¿Deseas agregar más stock al producto
              <span class="text-success">{{ editProduct.name }}</span
              >?
            </p>
            <b-row class="mt-2">
              <b-col>
                <b-button
                  class="rounded-button mr-1"
                  variant="primary"
                  @click="isQtyProduct = true"
                >
                  Sí
                </b-button>
                <b-button
                  class="rounded-button"
                  variant="danger"
                  @click="isModalQtyProduct = false"
                >
                  No
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <b-col v-else>
            <div class="text-center">
              <feather-icon
                icon="PlusCircleIcon"
                class="text-success mb-1"
                size="80"
              />
              <h4>
                Stock disponible:
                <span class="text-success">{{ editProduct.stock }}</span>
              </h4>
            </div>

            <b-form @submit.prevent="addQtyInProduct(qtyProduct, editProduct)">
              <b-row>
                <b-col>
                  <b-form-group label="Cantidad a agregar" class="mt-1">
                    <b-form-input
                      v-model="qtyProduct"
                      type="number"
                      min="1"
                      placeholder="Ingrese la cantidad"
                    />
                  </b-form-group>

                  <p>
                    <strong>Stock total:</strong>
                    <span class="text-success">
                      {{ Number(editProduct.stock) + Number(qtyProduct) }}
                    </span>
                  </p>
                </b-col>
              </b-row>
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="!qtyProduct"
              >
                Agregar
              </b-button>
            </b-form>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BButton,
  BBadge,
  BFormSpinbutton,
  BFormInput,
  BRow,
  BCol,
  BModal,
  BFormGroup,
  BForm,
} from "bootstrap-vue";
import store from "@/store";
import vSelect from "vue-select";
import vuescroll from "vuescroll";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormSpinbutton,
    vSelect,
    vuescroll,
    BFormInput,
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BForm,
  },
  computed: {
    cartProducts() {
      return this.$store.state["app-ecommerce"].cartItems.slice().reverse();
    },
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
    isAdmin() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
    },
  },
  props: {
    product: Object,
  },
  watch: {
    "product.qty"(newVal) {
      this.localQty = newVal;
    },
  },
  data() {
    return {
      localQty: this.product ? this.product.qty : 0,
      loadingSearchProduct: false,
      isModalQtyProduct: false,
      isQtyProduct: false,
      qtyProduct: 0,
      timeout: null,
      editProduct: {},
      selected: null,
      allProducts: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        rail: {
          background: "#01a99a",
        },
        bar: {
          background: "#ee3926",
        },
      },
    };
  },
  mounted() {
    if (this.isCashier) {
      this.getProducts();
    }
  },
  methods: {
    changeProductQuantity(product) {
      this.$store.commit("app-ecommerce/UPDATE_PRODUCT_CART", product);
      this.selected = null;
    },
    updateQtyInProduct($event, product) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        const newQty = parseInt($event, 10);
        if (!isNaN(newQty)) {
          if (newQty <= product.stock) {
            product.qty = newQty;
            this.$store.commit("app-ecommerce/UPDATE_PRODUCT_CART", product);
          } else {
            if (this.isCashier || this.isAdmin) {
              this.editProduct = product;
              this.isModalQtyProduct = true;
              this.qtyProduct = newQty - product.stock;
            } else {
              product.qty = product.stock;
              this.$store.commit("app-ecommerce/UPDATE_PRODUCT_CART", product);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: "La cantidad solicitada supera el stock disponible. Se ha ajustado a la cantidad máxima disponible.",
                },
              });
            }
          }
        }
      }, 500);
    },

    async addQtyInProduct(qty, product) {
      try {
        if (qty > 0) {
          const newStock = Number(product.stock) + Number(qty);
          await store.dispatch("products/update", {
            id: product.id,
            data: { stock: newStock },
          });

          product.stock = newStock;
          this.$store.commit("app-ecommerce/UPDATE_PRODUCT_CART", product);
          this.clearModalQtyProduct();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Éxito",
              icon: "TashIcon",
              variant: "success",
              text: "Stock agregado correctamente",
            },
          });
        }
      } catch (error) {
        this.clearModalQtyProduct();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: "No se pudo agregar el stock.",
          },
        });
      }
    },

    clearModalQtyProduct() {
      this.isModalQtyProduct = false;
      this.isQtyProduct = false;
      this.qtyProduct = 0;
      this.editProduct = {};
    },

    removeProductFromCart(product) {
      this.$store.commit("app-ecommerce/REMOVE_PRODUCT_FROM_CART", product.id);
      this.$store.commit(
        "app-ecommerce/UPDATE_CART_ITEMS_COUNT",
        this.$store.state["app-ecommerce"].cartItemsCount - 1
      );
    },
    async getProducts() {
      try {
        this.loadingSearchProduct = true;
        this.allProducts = await this.$store.dispatch("products/index", {
          store: true,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingSearchProduct = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
[dir] .b-form-spinbutton.form-control .btn-sm,
[dir] .b-form-spinbutton.form-control .btn-group-sm > .btn {
  padding: 0 !important;
}
</style>
